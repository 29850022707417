import styled, { css } from 'styled-components';
import { Fonts, rem } from '@Utils/styles';

interface WrapperProps {
	open: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	z-index: 15;
	font-family: ${Fonts.robotoSlab};
	font-size: ${rem(24)};
	transition: 0.5s all ease-in-out;
	overflow-y: hidden;
	${({ open }) =>
		open &&
		css`
			height: 100vh;
		`}
	.menu-container {
		height: 100vh;
		overflow-y: auto;
	}
`;

export const MenuList = styled.ul`
	padding-left: ${rem(10)};
	a {
		text-decoration: none;
	}
	&,
	ul {
		list-style: none;
	}
	> li {
		margin-bottom: ${rem(30)};
	}
	li {
		margin-top: ${rem(10)};
	}
`;
